.container {
 display: flex;
 justify-content: center;

}




@media (max-width: 700px) {

 .container {
  height: 100vh;
  width: 100vw;

 }



}

@media (max-width: 450px) {

  .container {
    height: 100vw;
    width: 100svh;

  }



}

.dropdown {
  float: left;
cursor: pointer;
  position: relative;
  z-index: 999;
}
.dropdown-content {
  display: none;
  position: absolute;
  /*  position with top bottom left right if needed */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.dropdown-content button {
  border: none;
  background-color: transparent;
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
/*.dropdown:hover .dropdown-content {*/
/*  display: block;*/
/*}*/

.dropdown-two {
  float: left;
cursor: pointer;
  position: relative;
}
.dropdown-two-content {
  display: none;
  position: absolute;
  /*  position with top bottom left right if needed */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;

}

.dropdown-two-content button {
  background-color: transparent;
  float: none;
  border: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}