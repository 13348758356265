.container {
  position: relative;
  width: fit-content;
  height: fit-content;
  left: 0rem;
  top: 13rem;
}
.boreholeHeader { 
   position: absolute;
   margin: 0rem;
   left: 50%;
   right: 50%;
   width: fit-content;
   transform: translate(-50%, -50%);
   top: 15.7rem;
  font-size: 1.2rem;
   color:#FAFAFA;
  font-size: 1.2rem;
}

.inner {
  position: absolute;
  width: 9.2rem;
  height: 14.2rem;
  left: 1.1rem;
  top: 1rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 4;
  overflow: hidden;
}

.innerUpper {
  position: absolute;
  height: 12.5rem;
  width: 8.7rem;
  border: 4px solid #234c69;
  border-bottom: none;
  left: 0rem;
  top: 0rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #dfeaed;
  z-index: 4;
}

.innerLower {
  position: absolute;
  height: 17.2rem;
  width: 18.5rem;
  border: 4px solid #234c69;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #234c69 10% 50%,
    #dfeaed 50% 100%
  );
  background-color: #dfeaed;
  left: -4.95rem;
  top: -3.68rem;
  z-index: 2;
}

.outrerRectangle {
  position: absolute;
  left: 0rem;
}

.levelContainer {
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 4;
  height: 10rem;
  width: 1.4rem;
}

.levelWhite {
  position: absolute;
}

.levelBlue {
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  z-index: 5;
}

.level {
  position: absolute;
  height: 8.3rem;
  width: 0.5rem;
  bottom: 1rem;
  left: 0.4rem;
  background-color: #0161ad;
  z-index: 5;
}

.level:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  background-color: #0161ad;
  border-radius: 50%;
  top: -0.25rem;
}
.level::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  background-color: #0161ad;
  border-radius: 50%;
  bottom: -0.25rem;
}
.criticalLevel {
   position: absolute;
   height: 6px;
   width: 0.5rem;
   background-color: #F65623;
   border-top: 2px;
   border-bottom: 2px;
   border-left: 1px;
   border-right: 1px;
   border-color: #D7F1FF;
   border-style: solid;
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
   left: 0.4rem;
   bottom: 3.2rem;
   z-index: 6;
}

.criticalLevel::before{
   content: "";
   position: absolute;
   height: 2px;
   width: 2px;
   left: -1px;
   border-radius: 50%;
   background-color: #F65623;
}
.criticalLevel::after {
   content: "";
   position: absolute;
   height: 2px;
   width: 2px;
   right: -1px;
   border-radius: 50%;
   background-color: #F65623;
}
.pump {
  position: absolute;
  top: 0rem;
  z-index: 5;
  top: 6.5rem;
  left: 7.25rem;
}

.pumpMiddle {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  top: 3.5rem;
  width: 1rem;
  height: 4rem;
  border: 4px solid #234c69;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pumpUpper {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  top: 1.05rem;
  width: 1rem;
  height: 0.4rem;
  border: 4px solid #234c69;
  border-radius: 4px;
}
.pumpLower {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  top: 6.1rem;
  width: 0.8rem;
  height: 0.7rem;
  border: 4px solid #234c69;
  border-radius: 4px;
}
.scale {
  position: absolute;
  top: 3.4rem;
  left: 2.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8.9rem;
  z-index: 4;
  width: 1rem;
}

.division {
  background-color: #7c7b7b;
  width: 2.05px;
  height: 1px;
}

.scaleNumbers {
  position: absolute;
  top: 2.9rem;
  left: 1.6rem;
  font-size: 0.6rem;
  align-items: center;
  height: 9.5rem;
  z-index: 4;
  width: 0.5rem;
  color: #7c7b7b;
}
.scaleNumbers .one {
  position: absolute;
  top: -0.7rem;
}
.scaleNumbers .two {
  position: absolute;
  top: 3.8rem;
}
.scaleNumbers .three {
  position: absolute;
  top: 8.2rem;
}
.pumpError{
  border-color:#F65623;
}